import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Lead } from "../../../components/ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "简介"
    }}>{`简介`}</h1>
    <Lead mdxType="Lead">
  Mirage 是一个可让前端开发人员模拟后端 API 的 JavaScript 库。
    </Lead>
    <Lead mdxType="Lead">
  与其他模拟库不同，Mirage 使重建动态
  场景变得容易，动态场景通常仅在实际
  生产环境中才可能出现。
    </Lead>
    <hr></hr>
    <p>{`几乎所有的 JavaScript 应用程序都需要与 HTTP API 进行交互。在开发过程中需要使用动态服务器数据时，通常有几种选择：`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`为后端服务做一个本地或托管版本的代理。`}</strong>{`如果您已经有一套 API 了，就可以这样做，但通常是没有。即使这样做，很多时候您还需要使用与实际 API 上不同的服务器状态。`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`注释掉应用程序的网络请求代码部分，并用虚拟数据填充。`}</strong>{`这是最快的选择，但是在您已经编写了很多应用程序代码之后，它会迫使您直接处理网络问题。`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`使用客户端拦截器处理应用程序的网络请求。`}</strong>{`一些 HTTP 客户端附带了模拟适配器（例如 `}<a parentName="p" {...{
            "href": "https://github.com/ctimmerm/axios-mock-adapter"
          }}>{`axios-mock-adapter`}</a>{` 可用于模拟由 `}<a parentName="p" {...{
            "href": "https://github.com/axios/axios"
          }}>{`axios`}</a>{` 发出的网络请求），还有诸如 `}<a parentName="p" {...{
            "href": "https://github.com/pretenderjs/pretender"
          }}>{`Pretender`}</a>{` 之类的独立工具，可用于在浏览器中拦截应用程序的网络请求。这是最灵活的方法，但是它要求你的每个项目从一开始就要引入这些工具，然后由您自己来在整个应用程序中实施某些功能。`}</p>
      </li>
    </ol>
    <p>{`Mirage 旨在解决这些问题。它是在客户端中运行的伪服务器，可以在开发和测试中使用，并且其自带来了足够多的常用功能以使您快速启动并运行。`}</p>
    <h2 {...{
      "id": "工作原理"
    }}>{`工作原理`}</h2>
    <p>{`Mirage 在浏览器中运行。它拦截您的 JavaScript 应用程序发出的任何 `}<inlineCode parentName="p">{`XMLHttpRequest`}</inlineCode>{` 或 `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` 发出的请求，并允许您模拟这些请求所对应的响应结果。这意味着您可以像在与真实服务器对话一样开发和测试您的应用程序。`}</p>
    <p>{`假设我们正在编写这个 React 组件：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// App.js
import React, { useState, useEffect } from "react"

export function App() {
  let [users, setUsers] = useState([])

  useEffect(() => {
    fetch("/api/users")
      .then((response) => response.json())
      .then((json) => setUsers(json))
  }, [])

  return (
    <ul>
      {users.map((user) => (
        <li key={user.id}>{user.name}</li>
      ))}
    </ul>
  )
}
`}</code></pre>
    <p>{`我们使用一个简单的 Mirage 路由处理器来处理 `}<inlineCode parentName="p">{`/api/users`}</inlineCode>{` 所发出的网络请求，如下所示：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx{3-13}"
      }}>{`// App.js
import React, { useState, useEffect } from "react"
import { createServer } from "miragejs"

createServer({
  routes() {
    this.get("/api/users", () => [
      { id: "1", name: "Luke" },
      { id: "2", name: "Leia" },
      { id: "3", name: "Anakin" },
    ])
  },
})

export function App() {
  let [users, setUsers] = useState([])

  useEffect(() => {
    fetch("/api/users")
      .then((response) => response.json())
      .then((json) => setUsers(json))
  }, [])

  return (
    <ul>
      {users.map((user) => (
        <li key={user.id}>{user.name}</li>
      ))}
    </ul>
  )
}
`}</code></pre>
    <p>{`重要的是，由于 Mirage 模拟的是 HTTP 部分，而不是您的应用程序用于发出网络请求的 JavaScript 代码，因此您无需修改​​ UI 代码即可了解您的应用程序是在与 Mirage 对话还是与实际的生产后端对话。`}</p>
    <p>{`除了拦截 HTTP 请求外，Mirage 还提供了一个模拟数据库和一系列辅助函数，可以轻松地模拟动态的后端服务。`}</p>
    <p>{`Mirage 借鉴了典型服务器端框架的概念，例如：`}</p>
    <ul>
      <li parentName="ul">{`处理 HTTP 请求的 `}<strong parentName="li">{`路由（routes）`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`数据库（database）`}</strong>{` 和 `}<strong parentName="li">{`数据模型（models）`}</strong>{` 用于存储数据和定义数据之间的关系`}</li>
      <li parentName="ul">{`用于 stubbing data 的 `}<strong parentName="li">{`工厂函数（factories）`}</strong>{` 和 `}<strong parentName="li">{`fixtures`}</strong></li>
      <li parentName="ul">{`用于格式化 HTTP 响应的 `}<strong parentName="li">{`序列化程序（serializers）`}</strong></li>
    </ul>
    <p>{`帮助您快速配置模拟服务器。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      